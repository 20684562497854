<form>
  <div class="modal-header" *ngIf="donationCategory">
    <div>
      <h1 style="margin: 0;"> <small>עריכת פרטי קטגוריה [{{ (id > 0 ? id : 'חדש') }}] </small>
        <br>
        {{ donationCategory.title }}
      </h1>
    </div>
  </div>
  <div class="modal-body" *ngIf="donationCategory">
    <div class="example-form" [formGroup]="donationCategoryForm" novalidate>
      <div class="col-sm-12">
        <fieldset class="form-group" fxLayout="column">
          <legend>פרטי קטגוריה</legend>

          <div class="form-row" fxLayout="row" fxLayout.xs="column">

            <div class="input-group mb-3">
              <label>קטגוריה:</label>
              <input type="text" class="form-control" formControlName="title" />
            </div>
          </div>
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">
              <label>מס' לחצן (1-9):</label>
              <input type="text" class="form-control" formControlName="keyNumber" />
            </div>
          </div>

          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <date-input [placeholder]="'תאריך פתיחה'"
              [dateControl]="donationCategoryForm.controls.openDate">Loading...</date-input>
          </div>
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">
              <label>אפשרות הקלטה...</label>
              <mat-select class="form-control"
                placeholder="אפשרות הקלטה..." formControlName="isIncludeRecord">
                <mat-option [value]="true">כן</mat-option>
                <mat-option [value]="false">לא</mat-option>
              </mat-select>
            </div>
          </div>
          <!-- <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">
              <label>מטבע:</label>
              <mat-select class="form-control  narrow" placeholder="מטבע..." formControlName="currency">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let c of servicesData.Currencies" [value]="c.value">
                  {{c.name}}
                </mat-option>
              </mat-select>
            </div>
          </div>-->
          <div class="form-row" fxLayout="row">
            <div class="input-group mb-3" fxLayout="column">
              <label>מסוף ישראל:</label>
              <mat-select class="form-control" placeholder="מסוף ישראל:" formControlName="il_TermID">
                <mat-option [value]="">...</mat-option>
                <mat-option *ngFor="let api of servicesData.Apis" [value]="api.value">
                  {{api.name}}
                </mat-option>
              </mat-select>
            </div>
            <div class="input-group mb-3" fxLayout="column">
              <label>מסוף ארה"ב:</label>
              <mat-select class="form-control" placeholder="מסוף ארה''ב':" formControlName="usa_TermID">
                <mat-option [value]="">...</mat-option>
                <mat-option *ngFor="let api of servicesData.Apis" [value]="api.value">
                  {{api.name}}
                </mat-option>
              </mat-select>
            </div>
          </div>
          <div class="form-row" fxLayout="row">
            <div class="input-group mb-3" fxLayout="column">
                <label>תבנית מכתב ישראל:</label>
                <mat-select placeholder="תבנית מכתב ישראל..." class="form-control" formControlName="il_ReceiptLetterSettingID">
                    <mat-option *ngFor="let ls of servicesData.LetterSettings" [value]="ls.id">
                        {{ls.title}}
                    </mat-option>
                </mat-select>
            </div>
          <div class="input-group mb-3" fxLayout="column">
              <label>תבנית מכתב ארה"ב:</label>
              <mat-select placeholder="תבנית מכתב ארה''ב..." class="form-control" formControlName="usa_ReceiptLetterSettingID">
                  <mat-option *ngFor="let ls of servicesData.LetterSettings" [value]="ls.id">
                      {{ls.title}}
                  </mat-option>
              </mat-select>
          </div>
      </div>
        </fieldset>
      </div>
    </div>
  </div>
  <footer-edit-buttons [onSaveGoToBack]="true" [navigateAfterSaving]="'donationCategories'"
    [dataProvider]="donationCategoriesData" [router]="router" [id]="id" [disabledDeleteButton]="false"
    [modelFormGroup]="donationCategoryForm">
  </footer-edit-buttons>
</form>
