import { TishEventsData } from './../tishEvents.data';
import { TishTablesData } from './../../tishTables/tishTables.data';
import { TishGroupsData } from './../../tishGroups/tishGroups.data';
import { TishGroupDlg } from './../../tishGroups/tishGroup/tishGroup-edit-dlg.cmp';
import { TishGroup } from './../../tishGroups/tishGroup/tishGroup';
import { TishTableDlg } from './../../tishTables/tishTable/tishTable-edit-dlg.cmp';
import { TishInvitedsData } from './../../tishInviteds/tishInviteds.data';
import { TishInvitedDlg } from './../../tishInviteds/tishInvited/tishInvited-edit-dlg.cmp';
import { TishTableMap } from './../../tishTables/tishTable/tishTable-map.cmp';
import { MatDialog } from '@angular/material/dialog';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewChildren, QueryList, HostListener, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TishEvent } from './tishEvent';
import { I } from '@angular/cdk/keycodes';
import { fromEvent } from 'rxjs';
import { TishTable } from 'src/app/tishTables/tishTable/tishTable';
import { TishInvited } from 'src/app/tishInviteds/tishInvited/tishInvited';
import { PinchZoomComponent } from 'src/app/components/ivypinch-pro/pinch-zoom.component';
import { tr } from 'date-fns/locale';
import { TishTableMoveDlg } from 'src/app/tishTables/tishTable/tishTable-move-dlg.cmp';
import { AutoCompleteComponent } from 'src/app/components/‏‏auto-complete/auto-complete.cmp';
import { TishArea } from 'src/app/tishAreas/tishArea/tishArea';
import { TishAreaDlg } from 'src/app/tishAreas/tishArea/tishArea-edit-dlg.cmp';
import { TishAreasData } from 'src/app/tishAreas/tishAreas.data';

declare var jQuery: any;;

interface circle {
  x: number;
  y: number;
  r: number;
};
@Component({

  selector: 'tishEvent-map',
  templateUrl: './tishEvent-map.tmpl.html',
  styleUrls: ['./../tishEvents.scss'],
  host: {
    "(window:resize)": "onWindowResize($event)"
  }
})

export class TishEventMap implements OnInit, AfterViewInit {
  isLoadingResults: boolean = false;
  initedsResultList: any[];
  docWidth: number = 1550;
  docHeight: number = 600;
  duplicates: any;
  viewDuplicates: boolean = false;


  constructor(
    private activatedRoute: ActivatedRoute,
    private tishEventsData: TishEventsData,
    private tishAreasData: TishAreasData,
    private formBuilder: FormBuilder,
    private tishTablesData: TishTablesData,
    private tishInvitedsData: TishInvitedsData,
    private tishGroupsData: TishGroupsData,
    public dialog: MatDialog,
    private router: Router,
    private renderer: Renderer2,
  ) {
  }

  @ViewChild('pinch') pinchZoom: PinchZoomComponent;

  onWindowResize(event) {
    this.docWidth = event.target.innerWidth - 180;
    this.docHeight = event.target.innerHeight - 180;
  }

  initSearchForm() {
    this.searchForm = this.formBuilder.group({
      eventID: [this.id, Validators.required],
      tishInvitedID: [],
      query: []
    });
  }

  searchTishTableName;
  searchTishPlaceNumber;
  searchSelect(event: any) {
    if (event.searchTishTableName) {
      this.searchTishTableName = event.searchTishTableName;
      this.searchTishPlaceNumber = event.searchTishPlaceNumber;
    } else {
      this.searchTishTableName = null;
      this.searchTishPlaceNumber = null;
    }
  }


  handlePinchZoomEvents($event: any) {
    console.log($event);
  }

  @ViewChild('canvas', { static: false }) canvas: ElementRef;
  @ViewChild('backCanvas', { static: false }) backCanvas: ElementRef;

  @ViewChildren('tablesChildren', { read: TishTableMap }) tablesChildren: QueryList<TishTableMap>;

  @ViewChild('helloContainer') helloContainer: HTMLDivElement;
  @ViewChild("searcAc") searcAc: AutoCompleteComponent;

  mousedown$;
  mouseup$;
  mousemove$;

  onDropedElementFn(e: boolean) {
    this.pinchZoom.setDisablePan(false);
  }

  startDragging(e) {
    this.pinchZoom.setDisablePan(true);
  }

  private listenForPlotChanges() {
    this.tablesChildren.changes.subscribe(
      (next: QueryList<TishTableMap>) => {
        if (next.first) {
          next.first.load();
        }
      }
    );
  }

  backroundImage = "./assets/img/html_logo.png"; // = `imagMap-TEMP.jpg`;
  tishEvent: TishEvent;
  eventStatus: any;
  id: number = 0;
  tishEventForm: FormGroup;
  searchForm: FormGroup;
  imageBlob: string;

  searchTishEventForm: FormGroup = this.formBuilder.group({
    page: [1],
    pageRows: [20],
    query: [],
    eventID: [this.id || null],
    areaID: [],
    groupID: [],
    tishGroupName: [],
    tishAreaName: [],
    contactID: [],
    notEmbedded: [true],
    tishInvitedID: [],
    tishInvitedName: [],
  });

  exportInviteds() {

  }

  public load(): void {
    if (this.id > 0) {
      this.tishEventsData.getMap(this.id)
        .subscribe((te: any) => {
          this.tishEvent = te;
          this.initMap();
          // this.fillForm(te);
        });
    }
  }

  public loadEventStatus(tishEventID): void {
    if ((tishEventID || this.id) > 0) {
      this.tishEventsData.getEventStatus(tishEventID || this.id)
        .subscribe(es => {
          this.eventStatus = es;
        });
    }
  }

  tableDrag(ev) {
    let jsnFrmStr = JSON.stringify(ev);
    ev.dataTransfer.setData('placeForm', jsnFrmStr);
  }

  private initForm() {
    this.tishEventForm = this.formBuilder.group({
      createBy: [], createDate: [], changeBy: [], changeDate: [],
      tishEventID: [this.id || 0, Validators.required],
      tishEventTitle: [],
      description: [],
      eventDate: [, Validators.required],
      comment: [],
      tishTablesIds: [],
      // tishTables: this.formBuilder.array([]),
    });
  }


  ngOnInit(): void {
    this.activatedRoute.params.forEach((params: Params) => {
      this.id = +params['tishEventID'];
    });

    this.initForm();
    this.initSearchForm();
    this.loadEventStatus(this.id);
    this.load();
  }
  obj: circle = {
    x: 1500,
    y: 500,
    r: 65,
  }

  isInsideCircle(e: MouseEvent) {
    return (e.clientX < this.obj.x + this.obj.r) && (e.clientX > this.obj.x - this.obj.r) && (e.clientY < this.obj.y + this.obj.r) && (e.clientY > this.obj.y - this.obj.r);
  }
  loadDuplicates(){

      this.tishEventsData.getDuplicates(this.id)
        .subscribe(d => {
          this.duplicates = d;
          this.viewDuplicates = true;
        });
  }

  initMap() {
    this.mousedown$ = fromEvent(document, 'mousedown');
    this.mouseup$ = fromEvent(document, 'mouseup');
    this.mousemove$ = fromEvent(document, 'mousemove');


  }

  hideMenubtns: boolean = true;
  onRightClick(e: any) {
    console.log(e);
    e.preventDefault();
    this.hideMenubtns = false;
    let canvas = jQuery(this.canvas.nativeElement);
    canvas.find('div.cntnr').css('display', 'inline');
    canvas.find('div.cntnr').css('left', (<any>e).offsetX);
    canvas.find('div.cntnr').css('top', (<any>e).offsetY);
  }

  setFullScreen() {
    var elem = this.canvas.nativeElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }
  }

  initSize() {
    this.pinchZoom.setTransform({ x: 1, y: 1, scale: 1 });

  }

  printMap() {
    let canvas = jQuery(this.canvas.nativeElement);
    let canvasParent = canvas.parent();
    let ap = $('#area-print');
    canvas.appendTo(ap);
    $('body').prepend(ap);
    window.print();
    setTimeout(function () {
      canvas.prependTo(canvasParent);
    }, 10);
  }


  ngAfterViewInit() {
    this.listenForPlotChanges();
    this.docWidth = window.innerWidth - 220;
    this.docHeight = window.innerHeight - 200;
  }

  clearSerch() {
    this.searchTishTableName = '';
    if (this.searcAc)
      this.searcAc.nameControl.setValue('');
  }


  public downloadExls(): void {

    this.tishEventsData.downloadExls(this.id)
      .subscribe(blob => {
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `דוח מוזמנים לאירוע ${this.tishEvent.tishEventTitle}.xlsx`;
        link.click();
      });
  }

  public downloadTicketsExls(): void {

    this.tishEventsData.downloadTicketsExls(this.id)
      .subscribe(blob => {
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `רשימת כרטיסים ${this.tishEvent.tishEventTitle}.xlsx`;
        link.click();
      });
  }

  public downloadTishEventTicketsForLettersExls(): void {

    this.tishEventsData.downloadTishEventTicketsForLettersExls(this.id)
      .subscribe(blob => {
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `יצוא למיזוג מכתבים עם נתוני כרטיסים ${this.tishEvent.tishEventTitle}.xlsx`;
        link.click();
      });
  }

  public downloadExlsForMerge(): void {

    this.tishEventsData.downloadExlsForMerge(this.id)
      .subscribe(blob => {
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `יצוא למיזוג מדבקות ${this.tishEvent.tishEventTitle}.xlsx`;
        link.click();
      });
  }
  public downloadExlsPlacesSticks(): void {

    this.tishEventsData.downloadExlsPlacesSticks(this.id)
      .subscribe(blob => {
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `רשימת מדבקות מקומות לשולחנות ${this.tishEvent.tishEventTitle}.xlsx`;
        link.click();
      });
  }


  public downloadPdf(): void {

    this.tishEventsData.downloadPdf(this.id)
      .subscribe(blob => {
        // saveAs(blob, 'testing.pdf');
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `PDF ${this.tishEvent.tishEventTitle}.pdf`;
        link.click();
        // const data = new Blob([blob], { type: 'application/pdf' });
        // saveAs(data);
        // console.log(data);
      });
  }

  moveTableDlg() {
    this.hideMenubtns = true;
    let tt = new TishTable();
    tt.tishEventID = this.tishEvent.tishEventID;
    tt.tishEventTitle = this.tishEvent.tishEventTitle;
    let e = (<any>event);
    tt.tishTableLeft = $(e.target).closest('.cntnr').css('left');
    tt.tishTableTop = $(e.target).closest('.cntnr').css('top');

    setTimeout(() => {
      const dialogRef = this.dialog.open(TishTableMoveDlg, {
        data: tt
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.valid) {
          this.moveTishTable(result);
        }
      });
    }, 200);
  }
  moveTishTable = (ttForm: FormGroup): void => {
    if (ttForm && ttForm.valid) {
      let ttData = this.tishTablesData;
      let te = this.tishEvent;
      this.tishTablesData.moveTable(<any>ttForm.value)
        .subscribe((x: any) => {
          this.tishTablesData.getMap(x.id)
            .subscribe((tt: TishTable) => {
              let tFrm = ttData.initForm();
              tFrm.patchValue(tt);
              let tid = tt.tishTableID;
              te.tishTablesIds.push(tid);
            });
        });
    }
  }


  addTableDlg() {
    this.hideMenubtns = true;
    let tt = new TishTable();
    tt.tishEventID = this.tishEvent.tishEventID;
    tt.tishEventTitle = this.tishEvent.tishEventTitle;
    let e = (<any>event);
    tt.tishTableLeft = $(e.target).closest('.cntnr').css('left');
    tt.tishTableTop = $(e.target).closest('.cntnr').css('top');

    setTimeout(() => {
      const dialogRef = this.dialog.open(TishTableDlg, {
        data: tt
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.valid) {
          this.createTishTable(result);
        }
      });
    }, 200);
  }

  createTishTable = (ttForm: FormGroup): void => {
    if (ttForm && ttForm.valid) {
      let ttData = this.tishTablesData;
      let te = this.tishEvent;
      this.tishTablesData.update(<any>ttForm.value)
        .subscribe((x: any) => {
          this.tishTablesData.getMap(x.id)
            .subscribe((tt: TishTable) => {
              let tFrm = ttData.initForm();
              tFrm.patchValue(tt);
              let tid = tt.tishTableID;
              te.tishTablesIds.push(tid);
            });
        });
    }
  }

  addInvitedDlg() {
    let ti = new TishInvited();
    ti.tishEventID = this.tishEvent.tishEventID;
    ti.tishEventTitle = this.tishEvent.tishEventTitle;

    setTimeout(() => {
      const dialogRef = this.dialog.open(TishInvitedDlg, {
        data: ti
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.valid) {
          this.createTishInvited(result);
        }
      });
    }, 200);
  }

  createTishInvited = (formData: FormGroup): void => {
    if (formData.valid) {
      this.tishInvitedsData.update(<any>formData.value)
        .subscribe((c: any) => {
          return true;
        })
    }
  }

  addGroupDlg() {
    let tg = new TishGroup();
    tg.tishEventID = this.tishEvent.tishEventID;
    tg.tishEventTitle = this.tishEvent.tishEventTitle;

    setTimeout(() => {
      const dialogRef = this.dialog.open(TishGroupDlg, {
        data: tg
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.valid) {
          this.createTishGroup(result);
        }
      });
    }, 200);
  }

  createTishGroup = (formData: FormGroup): void => {
    if (formData.valid) {
      this.tishGroupsData.update(<any>formData.value)
        .subscribe((c: any) => {
          return true;
        })
    }
  }



  addAreaDlg() {
    let ta = new TishArea();
    ta.tishEventID = this.tishEvent.tishEventID;
    ta.tishEventTitle = this.tishEvent.tishEventTitle;

    setTimeout(() => {
        const dialogRef = this.dialog.open(TishAreaDlg, {
            data: ta
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result && result.valid) {
                this.createTishArea(result);
            }
        });
    }, 200);
}
createTishArea = (formData: FormGroup): void => {
    if (formData.valid) {
        this.tishAreasData.update(<any>formData.value)
            .subscribe((c: any) => {
                return true;
            })
    }
}
}

